<script>
import axiosService from "@/services/axios.service";
import GameDate from "@/components/common/GameDate.vue";
import GameEvent from "@/components/common/GameEvent.vue";
import GameScore from "@/components/common/GameScore.vue";
import IndicateurCircle from "@/components/common/IndicateurCircle.vue";

export default {
  name: "PlayerPaneItem.H2H.Tab",
  components: {GameEvent, GameDate, IndicateurCircle, GameScore},
  props: {
    playerUid: {
      required: true
    },
    gameUid: {
      required: true
    }
  },

  data() {
    return {
      data: null
    }
  },
  beforeMount() {
    this._refreshData()
  },

  computed: {
    _events() {
      if (!this.data) return []
      const results = [...this.data.events]
      return results.sort((a, b) => {
        return new Date(a.date) - new Date(b.date)
      })
    },
    _games() {
      if (!this.data) return []
      return this.data.games
    },
    _sortedGameUIDs() {
      if(!this._games) return []
      return this._games.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      }).map(g => g.gameUID)
    }
  },

  methods: {
    _refreshData() {
      const payload = {
        gameUID: this.gameUid,
        playerUID: this.playerUid
      }
      axiosService.put('/dta/player/basic-data/h2h', payload).then(response => {
        this.data = response.data
      })
    },
    _gameEvents(gameUID){
      return this._events.filter(e => e.gameUID === parseInt(gameUID))
    }
  },
}
</script>

<template>
  <div>
    <fieldset class="no-border text-left mt-2">
      <legend class="m-0 ml-2" style="height: 22.5px; line-height: 22.5px">Historique des tête-à-tête</legend>
      <table v-if="data">
        <tr v-for="gameUID in _sortedGameUIDs">
          <td>
            <game-date :uid="gameUID"/>
          </td>
          <td class="events">
            <game-event :item="e" style="margin-right: -6px; display: inline-block" v-for="e in _gameEvents(gameUID)"/>
          </td>
        </tr>
      </table>
    </fieldset>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table.last-player-event-row {
  border-bottom: 1px solid @stroke-color;

  tr, td {
    border: none;
    padding: 0;
    background: transparent;
  }

  &.over {
    background-color: @hover-color;
  }
}
</style>