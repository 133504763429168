// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "@/services/axios.service";
import {bookmakerStore} from "@/stores/Bookmaker.store";

export const offerStore = defineStore('offerStore', {
    state: () => {
        return {
            definitions: null,
            families: null,

            valueIndicatorType: 'indicator',    // recommended_odd ou indicator
            valueIndicatorTypeTimeout: setInterval(() => {
                if(offerStore().valueIndicatorType === 'indicator') {
                    offerStore().valueIndicatorType = 'recommended_odd'
                }else{
                    offerStore().valueIndicatorType = 'indicator'
                }
            }, 2500),

            // labels
            labels: {
                101: "1X2 - Victoire dom. %OPERATOR% %VALUE% %BOOKMAKER%",
                102: "1X2 - Nul %OPERATOR% %VALUE% %BOOKMAKER%",
                103: "1X2 - Victoire ext. %OPERATOR% %VALUE% %BOOKMAKER%",

                201: "Double chance - Victoire Dom. ou nul %OPERATOR% %VALUE% %BOOKMAKER%",
                202: "Double chance - Pas de nul %OPERATOR% %VALUE% %BOOKMAKER%",
                203: "Double chance - Nul ou Victoire Ext. %OPERATOR% %VALUE% %BOOKMAKER%",

                301: "Buts - Plus de %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                302: "Buts - Moins de %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                401: "D/E - Victoire dom. %OPERATOR% %VALUE% %BOOKMAKER%",
                402: "D/E - Victoire ext. %OPERATOR% %VALUE% %BOOKMAKER%",

                501: "Asion Hand. - Victoire dom. %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                502: "Asian Hand. - Victoire ext. %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                601: "Buts Dom. OT - Plus de %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                602: "Buts Dom. OT -F Moins de %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                701: "Buts Ext. OT - Plus de %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                702: "Buts Ext. OT - Moins de %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                801: "Hand. - Victoire dom. %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                802: "Hand. - Victoire ext. %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                803: "Hand. - Nul %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                901: "Buts Dom. - Plus %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                902: "Buts Dom. - Moins %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                1001: "Buts Ext. - Plus %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                1002: "Buts Ext. - Moins %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                1101: "Buts MT - Plus %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                1102: "Buts MT - Moins %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                1201: "BTTS - Oui %BOOKMAKER%",
                1202: "BTTS - Non %BOOKMAKER%",

                1301: "Buts P2 - Plus %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                1302: "Buts P2 - Moins %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                1401: "1X2 P1 - Victoire dom. %OPERATOR% %VALUE% %BOOKMAKER%",
                1402: "1X2 P1 - Nul %OPERATOR% %VALUE% %BOOKMAKER%",
                1403: "1X2 P1 - Victoire ext. %OPERATOR% %VALUE% %BOOKMAKER%",

                1501: "1X2 P2 - Victoire dom. %OPERATOR% %VALUE% %BOOKMAKER%",
                1502: "1X2 P2 - Nul %OPERATOR% %VALUE% %BOOKMAKER%",
                1503: "1X2 P2 - Victoire ext. %OPERATOR% %VALUE% %BOOKMAKER%",

                1601: "Double chance P1 - Victoire Dom. ou nul %OPERATOR% %VALUE% %BOOKMAKER%",
                1602: "Double chance P1 - Pas de nul %OPERATOR% %VALUE% %BOOKMAKER%",
                1603: "Double chance P1 - Nul ou Victoire Ext. %OPERATOR% %VALUE% %BOOKMAKER%",

                1701: "Double chance P2 - Victoire Dom. ou nul %OPERATOR% %VALUE% %BOOKMAKER%",
                1702: "Double chance P2 - Pas de nul %OPERATOR% %VALUE% %BOOKMAKER%",
                1703: "Double chance P2 - Nul ou Victoire Ext. %OPERATOR% %VALUE% %BOOKMAKER%",

                1801: "BTTS P1 - Oui %BOOKMAKER%",
                1802: "BTTS P1 - Non %BOOKMAKER%",

                1901: "BTTS P2 - Oui %BOOKMAKER%",
                1902: "BTTS P2 - Non %BOOKMAKER%",
            },// labels

            shortLabels: {
                101: "1 %OPERATOR% %VALUE% %BOOKMAKER%",
                102: "X %OPERATOR% %VALUE% %BOOKMAKER%",
                103: "2 %OPERATOR% %VALUE% %BOOKMAKER%",

                201: "1|X %OPERATOR% %VALUE% %BOOKMAKER%",
                202: "1|2 %OPERATOR% %VALUE% %BOOKMAKER%",
                203: "X|2 %OPERATOR% %VALUE% %BOOKMAKER%",

                301: "O%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                302: "U%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                401: "1 %OPERATOR% %VALUE% %BOOKMAKER%",
                402: "2 %OPERATOR% %VALUE% %BOOKMAKER%",

                501: "1 %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                502: "2 %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                601: "O%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                602: "U%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                701: "O%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                702: "U%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                801: "1 %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                802: "2 %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                803: "X %ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                901: "O%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                902: "U%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                1001: "O%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                1002: "U%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                1101: "O%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                1102: "U%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                1201: "Oui %BOOKMAKER%",
                1202: "Non %BOOKMAKER%",

                1301: "O%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",
                1302: "U%ARGUMENT% %OPERATOR% %VALUE% %BOOKMAKER%",

                1401: "1 %OPERATOR% %VALUE% %BOOKMAKER%",
                1402: "X %OPERATOR% %VALUE% %BOOKMAKER%",
                1403: "2 %OPERATOR% %VALUE% %BOOKMAKER%",

                1501: "1 %OPERATOR% %VALUE% %BOOKMAKER%",
                1502: "X %OPERATOR% %VALUE% %BOOKMAKER%",
                1503: "2 %OPERATOR% %VALUE% %BOOKMAKER%",

                1601: "1|X %OPERATOR% %VALUE% %BOOKMAKER%",
                1602: "1|2 %OPERATOR% %VALUE% %BOOKMAKER%",
                1603: "X|2 %OPERATOR% %VALUE% %BOOKMAKER%",

                1701: "1|X %OPERATOR% %VALUE% %BOOKMAKER%",
                1702: "1|2 %OPERATOR% %VALUE% %BOOKMAKER%",
                1703: "X|2 %OPERATOR% %VALUE% %BOOKMAKER%",

                1801: "Oui %BOOKMAKER%",
                1802: "Non %BOOKMAKER%",

                1901: "Oui %BOOKMAKER%",
                1902: "Non %BOOKMAKER%",
            }
        }
    },
    actions: {
        // Permet de récupérer un match par son UID
        fetchFamilies(callback = null) {
            if (this.families !== null) {
                if (callback) callback()
                return
            }
            this.families = {}
            axiosService.get('/dta/offer-families').then(response => {
                this.families = response.data.results
            }).finally(() => {
                if (callback) callback()
            });
        },
        fetchOfferFamiliesDefinitions(callback = null) {
            if (this.definitions !== null) {
                if (callback) callback()
                return
            }
            this.definitions = {}
            axiosService.get('/dta/offer-families/definitions').then(response => {
                this.definitions = response.data.definitions
            }).finally(() => {
                if (callback) callback()
            });
        }
    },
    getters: {

        getSportFamilies: (state) => (sport) => {
            if (!state.families) {
                return []
            }
            if (!state.families[sport]) {
                return []
            }
            return state.families[sport]
        },

        getOfferByBookmakerUID: (state) => (offers, bookmakerUID) => {
            return offers.find(offer => offer.bookmakerUID === bookmakerUID)
        },

        getOfferValue: (state) => (offer) => {
            if (!offer) {
                return null
            }
            return offer.value.toFixed(2)
        },

        getOfferValuableIndicator: (state) => (offer) => {
            if (!offer) {
                return null
            }
            if (!offer.valuableIndicator) {
                return null
            }
            return offer.valuableIndicator.toFixed(2)
        },

        getOfferRecommendedOdd: (state) => (offer) => {
            if (!offer) {
                return null
            }
            if (!offer.recommendedOdd) {
                return null
            }
            return offer.recommendedOdd.toFixed(2)
        },

        getOfferProbability: (state) => (offer) => {
            if (!offer) {
                return null
            }
            if (!offer.valueProbability) {
                return null
            }
            return offer.valueProbability.toFixed(2)
        },

        getOfferFamilyBySportAndFixedUID: (state) => (sport, fixedUID) => {
            if (!state.definitions) {
                return null
            }
            let result = null

            if(!state.definitions[sport]){
                console.warn("No definitions for sport", sport, state.definitions)
                return null
            }

            state.definitions[sport].items.forEach(definition => {
                if (parseInt(definition.fixedUID) === parseInt(fixedUID)) {
                    result = definition
                }
            })
            return result
        },

        getOfferFamilyChoiceBySportAndFixedUID: (state) => (sport, fixedUID) => {
            if (!state.definitions) {
                return null
            }
            let result = null

            let defs = state.definitions[sport];
            if (!defs || !defs.items) {
                console.warn("No definitions for sport", sport, state.definitions)
                return null
            }

            defs.items.forEach(definition => {
                definition.choices.forEach(choice => {
                    if (parseInt(choice.fixedUID) === parseInt(fixedUID)) {
                        result = choice
                    }
                })
            })
            return result
        },

        getOfferFamilyDefinitions: (state) => () => {
            if (!state.definitions) {
                return []
            }
            return state.definitions
        },
        getOfferFamilyDefinitionsBySport: (state) => (sport) => {
            if (!state.definitions) {
                return []
            }
            return state.definitions[sport]
        },

        getOfferBookmakerValueLabel: (state) => (obv) => {
            if (!obv) {
                console.error("obv is required", obv)
                return "..."
            }

            let label = state.labels[obv.offerFamilyChoiceFixedUID]

            if (!label) {
                console.warn("Cannot found offer family choice label", obv, state.labels)
                label = "?"
            }

            if (label.indexOf("%OPERATOR%") !== -1) {
                label = label.replace("%OPERATOR%", "égale à")
            }

            if (label.indexOf("%VALUE%") !== -1) {
                label = label.replace("%VALUE%", obv.value)
            }

            if (label.indexOf("%ARGUMENT%") !== -1) {
                label = label.replace("%ARGUMENT%", obv.offerFamilyChoiceArgument)
            }

            if (label.indexOf("%BOOKMAKER%") !== -1) {
                const bookmaker = bookmakerStore().get(obv.bookmakerUID)
                if (!bookmaker) {
                    label = label.replace("%BOOKMAKER%", "chez un bookmaker.")
                } else {
                    label = label.replace("%BOOKMAKER%", bookmaker.name)
                }
            }

            return label
        },

        getOfferFamilyChoiceLabelByUID: (state) => (fixedUID, argument, bookmakerUID) => {
            if (!state.definitions) {
                return null
            }
            if (!fixedUID) {
                return null
            }

            let label = state.labels[fixedUID]

            if (label.indexOf("%ARGUMENT%") !== -1) {
                if (!argument) {
                    label = label.replace("%ARGUMENT%", "")
                } else {
                    label = label.replace("%ARGUMENT%", argument)
                }
            }

            if (label.indexOf("%BOOKMAKER%") !== -1) {
                if (bookmakerUID) {
                    const bookmaker = bookmakerStore().get(bookmakerUID)
                    if (!bookmaker) {
                        label = label.replace("%BOOKMAKER%", "chez un bookmaker. ")
                    } else {
                        label = label.replace("%BOOKMAKER%", "chez " + bookmaker.name + ". ")
                    }
                }else{
                    label = label.replace("%BOOKMAKER%", "")
                }
            }

            // replace all %% by arg
            return label.replace(/%.*?%/g, "")
        },

        getOfferFamilyChoiceLabel: (state) => (offerFamilyChoice, argument, short = false) => {
            if (!offerFamilyChoice) {
                console.error("offerFamilyChoice are required", offerFamilyChoice)
                return "..."
            }

            let label = short ? state.shortLabels[offerFamilyChoice.fixedUID] : state.labels[offerFamilyChoice.fixedUID]

            if (!label) {
                console.warn("Cannot found offer family choice label", offerFamilyChoice, state.labels)
                label = "?"
            }

            if (label.indexOf("%ARGUMENT%") !== -1) {
                if (!argument) {
                    label = label.replace("%ARGUMENT%", "")
                } else {
                    label = label.replace("%ARGUMENT%", argument)
                }
            }

            // replace all %% by empty string
            label = label.replace(/%.*?%/g, "")
            return label

        },


        getOfferFamilyDefinitionLabel: (state) => (code) => {
            switch (code) {
                case 'THREE_WAY':
                    return "Résultat final"
                case 'THREE_WAY_P1':
                    return "Résultat - Période 1"
                case 'THREE_WAY_P2':
                    return "Résultat - Période 2"
                case 'OVER_UNDER':
                    return "O/U"
                case 'OVER_UNDER_HOME':
                    return "O/U - Dom."
                case 'OVER_UNDER_AWAY':
                    return "O/U - Ext."
                case 'OVER_UNDER_P1':
                    return "O/U - P1"
                case 'OVER_UNDER_P2':
                    return "O/U - P2"
                case 'HANDICAP':
                    return "Handicap"
                case 'ASIAN_HANDICAP':
                    return "Handicap asiatique"
                case 'HOME_AWAY':
                    return "Domicile/Extérieur"
                case 'DOUBLE_CHANCE':
                    return "Double chance"
                case 'DOUBLE_CHANCE_P1':
                    return "Double chance - P1"
                case 'DOUBLE_CHANCE_P2':
                    return "Double chance - P2"
                case 'BTTS':
                    return "BTTS"
                case 'BTTS_P1':
                    return "BTTS - Période 1"
                case 'BTTS_P2':
                    return "BTTS - Période 2"
                default:
                    return code
            }
        },

        getOperandLabel: (state) => (offerFamilyChoice, operand) => {

            if (!offerFamilyChoice) {
                console.error("offerFamilyChoice are required", offerFamilyChoice)
                return "..."
            }

            if (!operand) {
                console.error("operand is required", operand)
                return "..."
            }

            let os = offerStore();
            let base = os.getOfferFamilyChoiceLabelByUID(operand.targetFixedUID, operand.argument, operand.condition.bookmakerUID)
            switch (operand.condition.operator) {
                case 'UPPER':
                    base += 'sup. à '
                    break
                case 'LOWER':
                    base += 'inf. à '
                    break
            }
            base += operand.condition.value;

            if (operand.condition.comparedToFixedUID) {
                base += ' et '
                switch (operand.condition.comparedToOperator) {
                    case 'UPPER':
                        base += 'sup. à '
                        break
                    case 'LOWER':
                        base += 'inf. à '
                        break
                }
                base += os.getOfferFamilyChoiceLabelByUID(operand.condition.comparedToFixedUID, operand.condition.comparedToArgument, null)
            }

            return base
        }
    }
})