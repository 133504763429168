<script>
import AnalysePaneHeader from "@/components/routes/selection/panes/analyse/header/AnalysePaneHeader.vue";
import AnalysePerspective from "@/components/routes/selection/panes/analyse/perspective/AnalysePerspective.vue";
import AnalysePerspectivesBottomBar
  from "@/components/routes/selection/panes/analyse/perspective/AnalysePerspectivesBottomBar.vue";

export default {
  name: "PlayerAnalysePane",
  components: {
    AnalysePaneHeader,
    AnalysePerspective,
    AnalysePerspectivesBottomBar
  },
}
</script>

<template>
  <div id="player-analyse-pane-wrapper">
    <div id="player-analyse-pane">
      <analyse-pane-header/>
      <analyse-perspective/>
      <analyse-perspectives-bottom-bar/>
    </div>
  </div>
</template>

<style lang="less">
@import "@/assets/styles/variables.less";

#player-analyse-pane {
  margin: @padding-small;
  border-radius: @radius-regular;
  background: rgb(34,34,34);
  background: linear-gradient(215deg, rgba(255,255,255,0.05) 0%, rgba(44,44,46,0.80) 30%, rgba(44,44,46,0.80) 40%, rgba(34,34,34,0.90) 100%);
  height: calc(100dvh - @toolbar-regular);
}

.mobile {
  #player-analyse-pane {
    display: none;
  }
}

</style>