// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "@/services/axios.service";

export const playerStore = defineStore('playerStore', {
    state: () => {
        return {
            loadingUIDs: [],
            fetchPlayerByUIDTimeout: null,
            items: []
        }
    },
    actions: {
        fetchGameByUIDs(uids) {
            if (uids.length === 0) {
                return
            }

            uids = uids.filter(uid => this.loadingUIDs.indexOf(uid) < 0)
            if (uids.length === 0) {
                return
            }

            let newUIDs = this.loadingUIDs.concat(uids)

            // remove duplicates
            this.loadingUIDs = [...new Set(newUIDs)]

            playerStore().fetchByLoadingUIDs()
        },
        addPlayers(players, callback) {
            this.items = this.items.concat(players)

            // remove duplicates
            this.items = [...new Set(this.items)]

            const allPlayersUIDs = this.items.map(item => item.uid)
            this.loadingUIDs = this.loadingUIDs.filter(uid => allPlayersUIDs.indexOf(uid) < 0)

            if (callback) callback()
        },
        fetchByLoadingUIDs() {
            if (this.fetchGameByUIDTimeout) clearTimeout(this.fetchGameByUIDTimeout)
            this.fetchGameByUIDTimeout = setTimeout(() => {

                // remove NaN from loadingUIDs
                this.loadingUIDs = this.loadingUIDs.filter(uid => !isNaN(uid))

                // remove empty values
                this.loadingUIDs = this.loadingUIDs.filter(uid => uid !== '')

                let joinedUIDs = this.loadingUIDs.join(',');
                if (joinedUIDs.length === 0) return
                axiosService.get('/dta/players/' + joinedUIDs).then(response => {
                    this.addPlayers(response.data.results)
                });

            }, 200)
        },
        // Permet de récupérer un match par son UID
        fetchPlayerByUID(uid) {
            const ps = playerStore()
            ps.fetchGameByUIDs([uid])
        }
    },
    getters: {

        get: (state) => (uid) => {

            if (state.loadingUIDs.indexOf(uid) >= 0) {
                return null
            }

            uid = parseInt(uid)

            let player = state.items.find(item => item.uid === uid);
            if (player) {
                return player
            }
            state.loadingUIDs.push(uid)

            // Si la player n'est pas dans le store, on le charge
            const ps = playerStore()
            ps.fetchByLoadingUIDs()

            return null
        },

        getAll: (state) => {
            return state.items
        },

        getRoles: state => uid => {
            const player = state.items.find(item => item.uid === uid);
            const roles = []
            if (!player) {
                return roles
            }
            if(player.role1Frequency > 0.1){
                roles.push({role: player.role1, frequency: player.role1Frequency})
            }
            if(player.role2Frequency > 0.1){
                roles.push({role: player.role2, frequency: player.role2Frequency})
            }
            if(player.role3Frequency > 0.1){
                roles.push({role: player.role3, frequency: player.role3Frequency})
            }

            return roles
        }
    }
})