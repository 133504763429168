// stores/counter.js
import {defineStore} from 'pinia'
import {selectionStore} from "@/stores/Selection.store";
import axiosService from "@/services/axios.service";
import {preferencesStore} from "@/stores/Preferences.store";
import {contextStore} from "@/stores/Context.store";
import {gameStore} from "@/stores/Game.store";
import dateService from "@/services/date.service";
import {notebookStore} from "@/stores/NotebookStore";

export const selectionPaneStore = defineStore('selectionPaneStore', {
    state: () => {
        return {

            selectionActiveUIDBySport: {
                'FOOTBALL': null,
                'BASKETBALL': null,
                'RUGBY': null,
                'HANDBALL': null,
                'HOCKEY': null,
                'AMERICAN_FOOTBALL': null,
            },

            selectedDateInterval: 'TODAY',
            selectedLeagueLabel: 'Toutes',
            selectedLeagueUIDs: [],
            arjelOnly: null,
            filter: '',
            // Les des matchs qui matchent avec la selection active
            isRunningSelection: true,
            results: [],
            gameAnalyzedCount: null,
            notAllowedLeagues: null,
            notAllowedResultsCount: null,
            minScore: null,
            maxScore: null,

            selectedGameUID: null,
            selectedItemTab: 'FORME',

            offerFamilyFixedUID: null,
            offerArgument: null
        }
    },
    actions: {
        init() {
            this.arjelOnly = preferencesStore().getBoolean('BETTING', 'ARJEL_ONLY', null)
        },

        onSportChange() {
            selectionPaneStore().runSelection()
        },

        setSelectionActiveUID(selectionUID, sport) {
            this.selectionActiveUIDBySport[sport] = selectionUID
        },
        /**
         * Permet l'exécution de la selection courante
         */
        runSelection() {
            this.isRunningSelection = true
            let ss = selectionStore();
            // build date interval
            const dateInterval = dateService.buildDateInterval(this.selectedDateInterval)

            const cs = contextStore()
            let payload = {
                sport: cs.getSport,
                filter: this.filter,
                dateInterval: dateInterval,
                leagueUIDs: this.selectedLeagueUIDs,
                arjelOnly: this.arjelOnly,
                forceGameUIDs: notebookStore().getItems()
            }
            const selectionActiveUID = this.selectionActiveUIDBySport[cs.getSport]
            if (!selectionActiveUID) {
                let sport = cs.getSport
                payload.selection = {
                    "name": "",
                    "type":"GAME",
                    "description": "",
                    "sport": sport,
                    "operation": {
                        "operator": "AND",
                        "operations": [],
                        "operands": []
                    }
                }
            } else {
                const selection = ss.isYours(selectionActiveUID)
                if (selection) {
                    payload.selection = selection
                } else {
                    payload.selectionUID = selectionActiveUID
                }
            }

            axiosService.put('/sel/game', payload).then(response => {

                this.results = response.data.results
                this.gameAnalyzedCount = response.data.gameAnalyzedCount
                this.notAllowedLeagues = response.data.notAllowedLeagues
                this.notAllowedResultsCount = response.data.notAllowedResultsCount
                this.minScore = response.data.minScore || 0
                this.maxScore = response.data.maxScore || 0

                this.minScore = parseFloat(this.minScore.toFixed(1))
                this.maxScore = parseFloat(this.maxScore.toFixed(1))

                const gameUIDs = this.results.map(result => result.gameUID)
                gameStore().fetchGameByUIDs(gameUIDs)
                selectionPaneStore().updateOffers()

            }).catch(error => {
                console.error('get games error', error)
            }).finally(() => {
                this.isRunningSelection = false
            });
        },

        switchArjelOnly() {
            if (this.arjelOnly === null) {
                this.arjelOnly = true
            } else if (this.arjelOnly === true) {
                this.arjelOnly = false
            } else {
                this.arjelOnly = null
            }
            preferencesStore().save('BETTING', 'ARJEL_ONLY', [this.arjelOnly])
        },

        updateOffers() {
            // On va chercher les cotes pour chaque match
            const gameUIDs = this.results.map(result => result.gameUID)
            if (gameUIDs.length === 0) {
                return
            }

            // On récupère la famille d'offre par défaut du sport
            const sps = selectionPaneStore()
            const cs = contextStore()

            let offerFamilyFixedUID = sps.getOfferFamilyFixedUID()
            if (!offerFamilyFixedUID) {
                console.warn('No offer family found for sport ' + cs.getSport)
                return;
            }

            let argParam = ''
            let offerArg = sps.getOfferArgument()
            if (offerArg) {
                argParam = '&arg=' + offerArg
            }

            let url = '/dta/offers?offerFamilyFixedUID=' + offerFamilyFixedUID.toString() + argParam
            const payload = {
                gameUIDs: gameUIDs
            }
            axiosService.post(url, payload).then(response => {
                const newResults = []
                this.results.forEach(result => {
                    let resultOffers = response.data.results[result.gameUID];
                    result.offers = resultOffers || []
                    newResults.push(result)
                })
                setTimeout(() => {
                    this.results = newResults
                }, 50)
            }).catch(error => {
                console.error('error get offers uids', error)
            });
        },

        setSelectedGameUID(gameUID) {
            if (this.selectedGameUID === gameUID) {
                this.selectedGameUID = null
                return
            }
            this.selectedGameUID = gameUID
        },

        clearSelection() {
            this.selectionActiveUIDBySport[contextStore().getSport] = null
        },

        setSelectedItemTab(tab) {
            this.selectedItemTab = tab
        },

        setOfferFamilyFixedUID(offerFamilyFixedUID) {
            this.offerFamilyFixedUID = offerFamilyFixedUID
        },
        setOfferArgument(offerArgument) {
            this.offerArgument = offerArgument
        },
        setSelectedLeagueLabel(leagueLabel) {
            this.selectedLeagueLabel = leagueLabel
        },
        setSelectedLeagueUIDs(leagueUIDs) {
            this.selectedLeagueUIDs = leagueUIDs
        }
    },
    getters: {

        /**
         * Retourne un nombre d'étoiles correspondant au score
         * @param state
         * @returns {(function(*))|*}
         */
        getRelativeMatchingScore: (state) => (score) => {

            if (score < state.minScore) {
                return 0
            }
            if (score >= state.maxScore) {
                return 1
            }

            return parseFloat(score / state.maxScore).toFixed(2)
        },

        getSelectedGameUID: (state) => () => {
            return state.selectedGameUID
        },

        getSelectedLeagueUIDs: (state) => () => {
            return state.selectedLeagueUIDs
        },

        getSelectionActiveUIDBySport: (state) => (sport) => {
            return state.selectionActiveUIDBySport[sport]
        },

        getOfferFamilyFixedUID: (state) => () => {
            let offerFamilyFixedUID = state.offerFamilyFixedUID
            if (!offerFamilyFixedUID) {
                let selectionActiveUID = state.selectionActiveUIDBySport[contextStore().getSport];
                if (selectionActiveUID) {
                    const ss = selectionStore()
                    const selection = ss.get(selectionActiveUID)
                    if (selection) {
                        offerFamilyFixedUID = selection.offerFamilyFixedUID
                    }
                }
            }
            if (!offerFamilyFixedUID) {
                const ps = preferencesStore()
                const cs = contextStore()
                offerFamilyFixedUID = ps.getFirstValue('BETTING', 'FAVORITE_OFFER_FAMILY_' + cs.getSport);
            }
            return offerFamilyFixedUID
        },

        getOfferArgument: (state) => () => {
            let offerArgument = state.offerArgument
            if (!offerArgument) {
                let selectionActiveUID = state.selectionActiveUIDBySport[contextStore().getSport];
                if (!selectionActiveUID) {
                    const ps = preferencesStore()
                    const cs = contextStore()
                    offerArgument = ps.getFirstValue('BETTING', 'FAVORITE_OFFER_FAMILY_ARG_' + cs.getSport)
                }
            }

            return offerArgument
        },
    }
})