<script>
import axiosService from "@/services/axios.service";
import {gameStore} from "@/stores/Game.store";

import PlayerName from "../../../../../../common/PlayerName.vue";
import PlayerPicture from "@/components/common/PlayerPicture.vue";
import SimpleGameRow from "@/components/routes/selection/panes/selection/items/tabs/SimpleGameRow.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {preferencesStore} from "@/stores/Preferences.store";
import YoutubeButton from "@/components/common/YoutubeButton.vue";
import {contextStore} from "@/stores/Context.store";

export default {
  name: "SelectionPaneItem.Similar.Tab",
  components: {YoutubeButton, FontAwesomeIcon, SimpleGameRow, PlayerPicture, PlayerName},

  props: {
    gameUid: {
      required: true
    }
  },

  data() {
    return {

      filters: {
        on1X2: true,
        on1X2Margin: 0.025,
        onUnderOver25: true,
        onUnderOver25Margin: 0.025,
        onBTTS: true,
        onBTTSMargin: 0.025,
        onLeague: false,
        onHomeTeam: false,
        onAwayTeam: false,
      },

      data: null,
      refreshTimeout: null
    }
  },

  beforeMount() {

    // Init filters from preferences
    this.filters.on1X2 = preferencesStore().getBoolean('SELECTION_SIMILAR_TAB', 'ON_1X2', true)
    this.filters.on1X2Margin = preferencesStore().getFloat('SELECTION_SIMILAR_TAB', 'ON_1X2_MARGIN', 0.025)
    this.filters.onUnderOver25 = preferencesStore().getBoolean('SELECTION_SIMILAR_TAB', 'ON_UNDER_OVER_25', true)
    this.filters.onUnderOver25Margin = preferencesStore().getFloat('SELECTION_SIMILAR_TAB', 'ON_UNDER_OVER_25_MARGIN', 0.025)
    this.filters.onBTTS = preferencesStore().getBoolean('SELECTION_SIMILAR_TAB', 'ON_BTTS', true)
    this.filters.onBTTSMargin = preferencesStore().getFloat('SELECTION_SIMILAR_TAB', 'ON_BTTS_MARGIN', 0.025)
    this.filters.onLeague = preferencesStore().getBoolean('SELECTION_SIMILAR_TAB', 'ON_LEAGUE', false)
    this.filters.onHomeTeam = preferencesStore().getBoolean('SELECTION_SIMILAR_TAB', 'ON_HOME_TEAM', false)
    this.filters.onAwayTeam = preferencesStore().getBoolean('SELECTION_SIMILAR_TAB', 'ON_AWAY_TEAM', false)
    this.filters.moment = preferencesStore().getString('SELECTION_SIMILAR_TAB', 'MOMENT', 'FT')

    this._refreshNow()
  },

  computed: {
    _game() {
      return gameStore().get(this.gameUid)
    },
    _hasFullLicence(){
      return contextStore().hasFullLicence();
    },
    _dataValid() {
      return this.data && this.data.gameUIDs && this.data.gameUIDs.length > 0
    },
    _gameUIDs() {
      if (!this.data) return []
      return this.data.gameUIDs
    },
    _gameUIDsLength() {
      return this._gameUIDs.length
    },
    _homeWinRate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.homeWinGames.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _awayWinRate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.awayWinGames.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _drawRate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.drawGames.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over05Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over05Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over15Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over15Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over25Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over25Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over35Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over35Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _bttsRate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.bttsGames.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _goalsSum() {
      if (!this.data) return 0
      return this.data.homeGoalsCount + this.data.awayGoalsCount
    },
    _goalsAvg() {
      if (this._gameUIDsLength === 0) return 0
      return (this._goalsSum / this._gameUIDsLength).toFixed(2)
    },
    _homeGoalsAvg() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.homeGoalsCount / this._gameUIDsLength).toFixed(2)
    },
    _awayGoalsAvg() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.awayGoalsCount / this._gameUIDsLength).toFixed(2)
    },
    _scoreRates() {
      if (!this.data) return []
      const results = {}
      Object.keys(this.data.scoreCountMap).forEach(item => {
        results[item] = (this.data.scoreCountMap[item].length / this._gameUIDsLength)
      })
      return results
    },
    _topScoresRate() {
      // sort _scoreRates by value
      const sorted = Object.keys(this._scoreRates).sort((a, b) => this._scoreRates[b] - this._scoreRates[a])
      return sorted.slice(0, 6)
    },
    _getHighestProbaScore() {
      if (!this._scoreRates) return 1
      return this._scoreRates[this._topScoresRate[0]]
    },
    _marginOptions() {
      // return all value between 0.01 and 0.03 by 0.0025
      const results = []
      for (let margin = 0.01; margin <= 0.03; margin += 0.0025) {
        // round to 4 decimals, to avoid floating point issues
        results.push(Math.round(margin * 10000) / 10000)
      }
      return results
    }
  },

  methods: {
    _refresh() {
      this.data = null // => loading
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(() => {
        this._refreshNow()
      }, 200)
    },
    async _refreshNow() {
      if (!this.gameUid) return

      if(!this._hasFullLicence){
        return
      }

      if (!this.filters.on1X2 && !this.filters.onUnderOver25 && !this.filters.onBTTS) {
        this.data = {
          gameUIDs: []
        }
        return
      }

      const payload = {
        gameUID: this.gameUid,
        moment: this.filters.moment,
        on1X2: this.filters.on1X2,
        on1X2Margin: this.filters.on1X2Margin,
        onUnderOver25: this.filters.onUnderOver25,
        onUnderOver25Margin: this.filters.onUnderOver25Margin,
        onBTTS: this.filters.onBTTS,
        onBTTSMargin: this.filters.onBTTSMargin,
        onLeague: this.filters.onLeague,
        onHomeTeam: this.filters.onHomeTeam,
        onAwayTeam: this.filters.onAwayTeam,
      }
      axiosService.put('/dta/game/basic-data/similar', payload).then(response => {
        this.data = response.data
      })
    },
    _roundProba(proba) {
      return (parseFloat(proba) * 100.0).toFixed(1) + '%'
    },
    _getProbaScoreColorTransparency(proba) {
      let opacity = proba / this._getHighestProbaScore;
      opacity = Math.pow(opacity, 2)
      return `rgba(0, 128, 0, ${opacity})`
    },
    _probaToOdd(proba) {
      if (parseFloat(proba) === 0.0) return '-.--'
      return (1.0 / (proba / 100.0)).toFixed(2)
    },
    _switchOn1X2() {
      this.filters.on1X2 = !this.filters.on1X2
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_1X2', [this.filters.on1X2])
    },
    _switchOnUnderOver25() {
      this.filters.onUnderOver25 = !this.filters.onUnderOver25
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_UNDER_OVER_25', [this.filters.onUnderOver25])
    },
    _switchOnBTTS() {
      this.filters.onBTTS = !this.filters.onBTTS
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_BTTS', [this.filters.onBTTS])
    },
    _switchOnLeague() {
      this.filters.onLeague = !this.filters.onLeague
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_LEAGUE', [this.filters.onLeague])
    },
    _switchOnHome() {
      this.filters.onHomeTeam = !this.filters.onHomeTeam
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_HOME_TEAM', [this.filters.onHomeTeam])
    },
    _switchOnAway() {
      this.filters.onAwayTeam = !this.filters.onAwayTeam
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_AWAY_TEAM', [this.filters.onAwayTeam])
    },
    _onSelect1X2Margin(margin) {
      this.filters.on1X2Margin = margin
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_1X2_MARGIN', [this.filters.on1X2Margin])
    },
    _onSelectUnderOver25Margin(margin) {
      this.filters.onUnderOver25Margin = margin
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_UNDER_OVER_25_MARGIN', [this.filters.onUnderOver25Margin])
    },
    _onSelectBTTSMargin(margin) {
      this.filters.onBTTSMargin = margin
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_BTTS_MARGIN', [this.filters.onBTTSMargin])
    },
    _setMoment(moment) {
      this.filters.moment = moment
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'MOMENT', [this.filters.moment])
    }
  }
}
</script>

<template>

  <div v-if="_hasFullLicence">
    <div class="filters">
      <div class="mx-1 flex">
        <fieldset class="mr-1">
          <legend class="m-0">1X2</legend>
          <div>
            <button :class="{'small no-radius':true, 'primary selected' : filters.on1X2}" @click="_switchOn1X2">
              {{ (filters.on1X2) ? 'Oui' : 'Non' }}
            </button>

            <a-dropdown :trigger="['click']" class="flex-grow-0">
              <button class="small no-radius" @click.prevent :disabled="!filters.on1X2">
                {{ filters.on1X2Margin * 100.0 }}%
              </button>
              <template #overlay>
                <a-menu>
                  <a-menu-item v-for="margin in _marginOptions" @click="_onSelect1X2Margin(margin)">{{
                      (margin * 100.0).toFixed(2)
                    }}%
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </fieldset>
        <fieldset class="mr-1">
          <legend class="m-0">+/- 2.5</legend>
          <div>
            <button :class="{'small no-radius':true, 'primary selected' : filters.onUnderOver25}"
                    @click="_switchOnUnderOver25">{{ (filters.onUnderOver25) ? 'Oui' : 'Non' }}
            </button>

            <a-dropdown :trigger="['click']" class="flex-grow-0">
              <button class="small no-radius" :disabled="!filters.onUnderOver25" @click.prevent>
                {{ filters.onUnderOver25Margin * 100.0 }}%
              </button>
              <template #overlay>
                <a-menu>
                  <a-menu-item v-for="margin in _marginOptions" @click="_onSelectUnderOver25Margin(margin)">{{
                      (margin * 100.0).toFixed(2)
                    }}%
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </fieldset>
        <fieldset class="mr-1">
          <legend class="m-0">BTTS</legend>
          <div>
            <button :class="{'small no-radius':true, 'primary selected' : filters.onBTTS}"
                    @click="_switchOnBTTS">{{ (filters.onBTTS) ? 'Oui' : 'Non' }}
            </button>

            <a-dropdown :trigger="['click']" class="flex-grow-0">
              <button class="small no-radius" :disabled="!filters.onBTTS" @click.prevent>
                {{ filters.onBTTSMargin * 100.0 }}%
              </button>
              <template #overlay>
                <a-menu>
                  <a-menu-item v-for="margin in _marginOptions" @click="_onSelectBTTSMargin(margin)">{{
                      (margin * 100.0).toFixed(2)
                    }}%
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </fieldset>
      </div>
      <a-alert v-if="!filters.on1X2 && !filters.onUnderOver25 && !filters.onBTTS" class="mt-1"
               message="Au moins un critère côte doit être actif." type="warning" show-icon/>
      <hr class="my-1"/>
      <div class="mx-1 flex">
        <fieldset class="mr-1">
          <legend class="m-0">Eq.Dom</legend>
          <div>
            <button :class="{'small no-radius':true, 'primary selected' : filters.onHomeTeam}" @click="_switchOnHome">
              {{ (filters.onHomeTeam) ? 'Oui' : 'Non' }}
            </button>
          </div>
        </fieldset>
        <fieldset class="mr-1">
          <legend class="m-0">Eq.Ext</legend>
          <div>
            <button :class="{'small no-radius':true, 'primary selected' : filters.onAwayTeam}" @click="_switchOnAway">
              {{ (filters.onAwayTeam) ? 'Oui' : 'Non' }}
            </button>
          </div>
        </fieldset>
        <fieldset class="mr-1">
          <legend class="m-0">Ligue</legend>
          <div>
            <button :class="{'small no-radius':true, 'primary selected' : filters.onLeague}" @click="_switchOnLeague">
              {{ (filters.onLeague) ? 'Oui' : 'Non' }}
            </button>
          </div>
        </fieldset>
      </div>
    </div>

    <div v-if="_dataValid" class="text-left">
      <hr/>

      <a-alert v-if="_gameUIDsLength > 0 && _gameUIDsLength < 100" class="m-1"
               message="Minimum de 100 matchs similaires recommandé." type="warning" show-icon/>

      <div class="flex">
        <legend class="m-0 ml-2 white" style="height: 22.5px; line-height: 22.5px">{{ _gameUIDsLength }} matchs similaires</legend>
        <div class="flex-grow"/>
        <a-dropdown :trigger="['click']">
          <button :class="{'small no-radius' : true}" style="height: 22.5px">
            ({{ filters.moment }})
          </button>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="_setMoment('FT')" class="text-left">
                FT
              </a-menu-item>
              <a-menu-item @click="_setMoment('P1')" class="text-left">
                P1
              </a-menu-item>
              <a-menu-item @click="_setMoment('P2')" class="text-left">
                P2
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>

      <fieldset class="m-1 text-left no-border">
        <table>
          <tr>
            <th></th>
            <th style="width: 40px">1</th>
            <th style="width: 40px">X</th>
            <th style="width: 40px" class="sr">2</th>
            <th>+0.5</th>
            <th>+1.5</th>
            <th>+2.5</th>
            <th class="sr">+3.5</th>
            <th style="width: 45px">BTTS</th>
          </tr>
          <tr>
            <td>%</td>
            <td>{{ _homeWinRate }}%</td>
            <td>{{ _drawRate }}%</td>
            <td class="sr">{{ _awayWinRate }}%</td>
            <td style="width: 32px">{{ _over05Rate }}%</td>
            <td style="width: 32px">{{ _over15Rate }}%</td>
            <td style="width: 32px">{{ _over25Rate }}%</td>
            <td class="sr" style="width: 40px">{{ _over35Rate }}%</td>
            <td style="width: 40px">{{ _bttsRate }}%</td>
          </tr>
          <tr>
            <td>Val</td>
            <td>{{ _probaToOdd(_homeWinRate) }}</td>
            <td>{{ _probaToOdd(_drawRate) }}</td>
            <td class="sr">{{ _probaToOdd(_awayWinRate) }}</td>
            <td>{{ _probaToOdd(_over05Rate) }}</td>
            <td>{{ _probaToOdd(_over15Rate) }}</td>
            <td>{{ _probaToOdd(_over25Rate) }}</td>
            <td class="sr">{{ _probaToOdd(_over35Rate) }}</td>
            <td>{{ _probaToOdd(_bttsRate) }}</td>
          </tr>
        </table>
      </fieldset>

      <fieldset class="m-1 text-left no-border">
        <table>
          <tr>
            <th></th>
            <th style="width: 40px">1X</th>
            <th style="width: 40px">12</th>
            <th style="width: 40px" class="sr">X2</th>
            <th>-0.5</th>
            <th>-1.5</th>
            <th>-2.5</th>
            <th class="sr">-3.5</th>
            <th style="width: 45px">!BTTS</th>
          </tr>
          <tr>
            <td>%</td>
            <td>{{ 100 - _awayWinRate }}%</td>
            <td>{{ 100 - _drawRate }}%</td>
            <td class="sr">{{ 100 - _homeWinRate }}%</td>
            <td style="width: 32px">{{ 100 - _over05Rate }}%</td>
            <td style="width: 32px">{{ 100 - _over15Rate }}%</td>
            <td style="width: 32px">{{ 100 - _over25Rate }}%</td>
            <td class="sr" style="width: 40px">{{ 100 - _over35Rate }}%</td>
            <td style="width: 40px">{{ 100 - _bttsRate }}%</td>
          </tr>
          <tr>
            <td>Val</td>
            <td>{{ _probaToOdd(100 - _awayWinRate) }}</td>
            <td>{{ _probaToOdd(100 - _drawRate) }}</td>
            <td class="sr">{{ _probaToOdd(100 - _homeWinRate) }}</td>
            <td>{{ _probaToOdd(100 - _over05Rate) }}</td>
            <td>{{ _probaToOdd(100 - _over15Rate) }}</td>
            <td>{{ _probaToOdd(100 - _over25Rate) }}</td>
            <td class="sr">{{ _probaToOdd(100 - _over35Rate) }}</td>
            <td>{{ _probaToOdd(100 - _bttsRate) }}</td>
          </tr>
        </table>
      </fieldset>

      <div>
        <fieldset class="m-1 no-border mt-3 text-left p-0">
          <legend class="m-0">Nombre de buts</legend>
          <table>
            <tr>
              <th>Tot</th>
              <th>Dom</th>
              <th>Ext</th>

            </tr>
            <tr>
              <td style="width: 32px">{{ _goalsAvg }}</td>
              <td style="width: 32px">{{ _homeGoalsAvg }}</td>
              <td style="width: 32px">{{ _awayGoalsAvg }}</td>

            </tr>
          </table>
        </fieldset>
        <fieldset class="m-1 mt-3 text-left no-border">
          <legend class="m-0">Scores fréquents</legend>
          <div class="mb-2">
            <a-button-group v-for="score in _topScoresRate" class="m-1 inline-block">
              <button class="info no-radius-right">
                {{ score }}
              </button>
              <button class="no-radius-left"
                      :style="{'backgroundColor': _getProbaScoreColorTransparency(_scoreRates[score])}">
                {{ _roundProba(_scoreRates[score]) }}
              </button>
            </a-button-group>
          </div>
        </fieldset>
      </div>
      <hr/>
      <div class="flex p-2">
        <p class="pr-2 m-0 flex-grow">🔍 Avec cet onglet, découvre l'historique des résultats de matchs aux profils de cotes similaires ! 📊 Analyse poussée = décisions plus éclairées ! 💡</p>
        <youtube-button video-id="PSI-QYT6NcI"/>
      </div>
      <hr/>
      <fieldset class="m-1 no-border text-left p-0">
        <legend>Echantillon</legend>
        <table>
          <simple-game-row :game-uid="gameUID" v-for="gameUID in _gameUIDs.slice(0,20)" :key="gameUID"/>
        </table>
      </fieldset>
    </div>
    <a-alert v-else-if="!data" message="Chargement..." type="info" class="m-2"/>
    <a-alert v-else type="error" class="m-2" show-icon
             message="Aucune donnée disponible ne correspondant à la demande."/>
  </div>
  <div v-else>
    <a-alert
             message="Il faut une licence ULTIMATE pour utiliser l'analyse par profil de côte."
             type="info" show-icon class="m-1"/>
  </div>

</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.filters {
  background: @background-color-light;
  padding: @padding-small;
}

fieldset {
  background: @background-color;
}

</style>