<script>

import {selectionStore} from "@/stores/Selection.store";
import {modalStore} from "@/stores/Modal.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {contextStore} from "@/stores/Context.store";
import ArjelLogo from "@/components/common/ArjelLogo.vue";
import {message} from "ant-design-vue";
import {playerPaneStore} from "@/components/routes/player/panes/player/PlayerPane.store";
import {preferencesStore} from "@/stores/Preferences.store";
import {leagueGroupsStore} from "@/stores/LeagueGroups.store";

export default {
  name: "PlayerPaneHeader",
  components: {ArjelLogo, FontAwesomeIcon},

  data() {
    return {
      displayedPage: 1,

      filter: '',
      filterTimeout: null
    }
  },
  computed: {
    _dateIntervalChoices() {
      return [
        {
          label: 'Plus tôt aujourd\'hui',
          shortLabel: '+tôt',
          code: 'EARLIER_TODAY'
        },
        {
          label: 'Aujourd\'hui',
          shortLabel: 'Auj.',
          code: 'TODAY'
        },
        {
          label: '24 prochaines heures',
          shortLabel: '24H',
          code: 'NEXT_24_HOURS'
        },
        {
          label: '3 prochains jours',
          shortLabel: '3J',
          code: 'NEXT_3_DAYS'
        },
        {
          label: '7 prochains jours',
          shortLabel: '7J',
          code: 'NEXT_7_DAYS'
        }
      ]
    },
    _selectionActive() {
      let selectedSelectionUID = playerPaneStore().getSelectionActiveUIDBySport(contextStore().getSport);
      return selectionStore().get(selectedSelectionUID);
    },
    _selectionActiveName() {
      let selectionActive = this._selectionActive;
      if (!selectionActive) return "Choisir une sélection";

      // Keep only the first 20 characters
      if (selectionActive.name.length > 25) {
        return selectionActive.name.substring(0, 25) + '...'
      }

      return selectionActive.name
    },
    _selectedDateInterval() {
      return this._dateIntervalChoices.find(c => c.code === playerPaneStore().selectedDateInterval);
    },
    _selectedDateIntervalLabel() {
      if (!this._selectedDateInterval) return ''
      return this._selectedDateInterval.shortLabel
    },
    _leagueGroups() {
      let groups = leagueGroupsStore().getBySport(contextStore().getSport);
      groups.sort((a, b) => a.name.localeCompare(b.name))
      return groups
    },
    _selectedLeaguesLabel() {
      let label = playerPaneStore().selectedLeagueLabel;
      if (!label) return 'Toutes'
      return label
    },
    _arjelOnly() {
      return playerPaneStore().arjelOnly
    }
  },
  methods: {
    _onClickSelection() {
      modalStore().openModal({uid: "selectionsModal", payload: {type: "PLAYER"}})
    },
    _clearSelection() {
      playerPaneStore().clearSelection()
      playerPaneStore().runSelection()
    },
    _onSelectDateInterval(option) {
      if (option === 'NEXT_3_DAYS' || option === 'NEXT_7_DAYS') {
        if (!contextStore().isAuthenticated()) {
          message.warn("Connectes toi pour avoir accès à plus de jours d'analyse")
          return
        }
      }
      playerPaneStore().selectedDateInterval = option;

      // On relance la recherche
      this.displayedPage = 1
      playerPaneStore().runSelection();
    },
    _switchArjelOnly() {
      playerPaneStore().switchArjelOnly()

      // On relance la recherche
      this.displayedPage = 1
      playerPaneStore().runSelection();
    },
    _useSportFavoritesLeagues() {
      playerPaneStore().setSelectedLeagueLabel('Favorites')
      let values = preferencesStore().getValues('LEAGUE', 'FAVORITE_LEAGUES_' + contextStore().getSport);
      if (!values || !values.length) {
        message.warn("Tu n'as pas définit de compétitions préférées pour ce sport")
        return
      }
      // convert string to number
      values = values.map(v => Number(v))
      playerPaneStore().selectedLeagueUIDs = values;

      // On relance la recherche
      this.displayedPage = 1
      playerPaneStore().runSelection();
    },
    _clearLeaguesSelection() {
      playerPaneStore().setSelectedLeagueLabel('Toutes')
      playerPaneStore().setSelectedLeagueUIDs([])

      // On relance la recherche
      this.displayedPage = 1
      playerPaneStore().runSelection();
    },
    _selectGroup(group) {
      playerPaneStore().setSelectedLeagueLabel(group.name)
      playerPaneStore().setSelectedLeagueUIDs(group.values)

      // On relance la recherche
      this.displayedPage = 1
      playerPaneStore().runSelection();
    },
    _openLeagueGroupsModal() {
      modalStore().openModal({
        uid: "leagueGroupsModal",
        payload: {
          sport: this._contextStore.getSport
        }
      })
    }
  },
  watch: {
    filter: function (val) {
      clearTimeout(this.filterTimeout);
      this.filterTimeout = setTimeout(() => {
        playerPaneStore().filter = val
        playerPaneStore().runSelection()
      }, 300);
    }
  }
}
</script>

<template>
  <div id="player-pane-header">
    <header>
      <div class="flex pt-1 mx-1">
        <button class="accent" @click="_onClickSelection">
          {{ _selectionActiveName }}
        </button>
        <div class="flex-grow"/>
        <button class="info" v-if="_selectionActive" @click="_clearSelection">
          <font-awesome-icon icon="fa-solid fa-close"/>
        </button>
      </div>
    </header>
    <header class="px-1 py-1 flex">
      <a-dropdown :trigger="['click']" class="flex-grow-0">
        <button class="info" @click.prevent>{{ _selectedDateIntervalLabel }}</button>
        <template #overlay>
          <a-menu>
            <a-menu-item v-for="c in _dateIntervalChoices" @click="_onSelectDateInterval(c.code)">{{
                c.label
              }}
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>

      <a-dropdown :trigger="['click']" class="flex-grow-0">
        <a-tooltip placement="right" title="Sélectionner des compétitions">
          <button class="ml-1 info">
            <font-awesome-icon icon="fa-solid fa-trophy"/>
            {{ _selectedLeaguesLabel }}
          </button>
        </a-tooltip>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="_switchArjelOnly">
              <div class="flex text-center">
                <arjel-logo :height="24"/>
                <button class="primary ml-2" v-if="_arjelOnly===true">Uniquement</button>
                <button class="secondary ml-2" v-else-if="_arjelOnly===false">Hors ARJEL</button>
                <button class="info ml-2" v-else>Indifférent</button>
              </div>
            </a-menu-item>
            <hr/>
            <a-menu-item @click="_useSportFavoritesLeagues">
              Compétitions préférées
            </a-menu-item>
            <a-menu-item @click="_clearLeaguesSelection">
              Toutes les compétitions
            </a-menu-item>
            <hr/>
            <a-menu-item @click="_selectGroup(g)" v-for="g in _leagueGroups">
              {{ g.name }}
            </a-menu-item>
            <hr/>
            <a-menu-item @click="_openLeagueGroupsModal">
              Gestion des groupes
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>

      <input v-model="filter" placeholder="Cherche un joueur..." class="ml-1 flex-grow">

    </header>
  </div>
</template>