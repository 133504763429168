<script>

import Statistic from "@/components/common/Statistic.vue";
import TeamName from "@/components/common/TeamName.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";

// Tabs
import GamblingAffiliationAd from "@/components/common/GamblingAffiliationAd.vue";
import SelectionMatch from "@/components/common/SelectionMatch.vue";
import PlayerName from "@/components/common/PlayerName.vue";
import PlayerPicture from "@/components/common/PlayerPicture.vue";
import SelectionPaneItemLineGameData
  from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.LineGameData.vue";
import {gameStore} from "@/stores/Game.store";
import SelectionPaneItemTabs from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.Tabs.vue";
import PlayerPaneItemTabs from "@/components/routes/player/panes/player/PlayerPaneItem.Tabs.vue";
import {playerPaneStore} from "@/components/routes/player/panes/player/PlayerPane.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import SelectionPaneItemOfferMatch
  from "@/components/routes/selection/panes/selection/items/SelectionPaneItemOfferMatch.vue";
import {playerStore} from "@/stores/Player.store";
import SelectionPaneItemLine1 from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.Line1.vue";
import PlayerRole from "@/components/common/PlayerRole.vue";


export default {
  name: "PlayerPaneItem",
  components: {
    PlayerRole,
    SelectionPaneItemLine1,
    SelectionPaneItemOfferMatch,
    FontAwesomeIcon,
    SelectionPaneItemTabs,
    SelectionPaneItemLineGameData,
    TeamLogo,
    PlayerPicture,
    PlayerName,
    SelectionMatch,
    GamblingAffiliationAd,
    TeamName,
    Statistic,
    PlayerPaneItemTabs,
  },
  props: {
    item: {
      required: true
    },
    showAd: {
      required: true
    }
  },

  data() {
    return {
      odds: null
    }
  },

  computed: {
    _game() {
      if (!this.item) return null
      return gameStore().get(this.item.gameUID)
    },
    _player() {
      if (!this.item) return null
      return playerStore().get(this.item.playerUID)
    },
    _playerRoles(){
      const results = []
      if(!this._player) return results
      if(this._player.role1) results.push(this._player.role1)
      if(this._player.role2) results.push(this._player.role2)
      if(this._player.role3) results.push(this._player.role3)
      return results
    },
    _homeHomeTeamUID() {
      if (!this._game) return null
      return this._game.homeTeamUID
    },
    _homeAwayTeamUID() {
      if (!this._game) return null
      return this._game.awayTeamUID
    },
    _cssClasses() {
      return {
        'player-pane-item': true,
        'selected': this._isSelected
      };
    },
    _matchingScore() {
      if (!this.item.matchingScore) return null
      return parseFloat(this.item.matchingScore.toFixed(1))
    },
    _relativeMatchingScore() {
      return playerPaneStore().getRelativeMatchingScore(this._matchingScore)
    },
    _matchingScoreTooltip() {
      return 'Score : ' + this._matchingScore
    },
    _selectedPlayerUID() {
      return playerPaneStore().selectedPlayerUID
    },
    _isSelected() {
      return this._selectedPlayerUID === this.item.playerUID;
    },
    _playerStats() {
      if (!this.item.playerStats) return null

      const result = {
        bench: 0,
        lineups: 0,
        subsIn: 0,
        subsOut: 0,

        shotsOn: 0,
        shotsTotal: 0,

        goalsAssists: 0,
        goalsTotal: 0,

        gamesPlayed: 0,
        minutesPlayed: 0,

        redCards: 0,
        yellowCards: 0,
        yellowRedCards: 0,

      }
      this.item.playerStats.forEach(stat => {
        result.bench += stat.bench || 0
        result.lineups += stat.lineups || 0
        result.subsIn += stat.subsIn || 0
        result.subsOut += stat.subsOut || 0

        result.shotsOn += stat.shotsOn || 0
        result.shotsTotal += stat.shotsTotal || 0

        result.goalsAssists += stat.goalsAssists || 0
        result.goalsTotal += stat.goalsTotal || 0

        result.gamesPlayed += stat.gamesPlayed || 0
        result.minutesPlayed += stat.minutesPlayed || 0

        result.redCards += stat.redCards || 0
        result.yellowCards += stat.yellowCards || 0
        result.yellowRedCards += stat.yellowRedCards || 0
      })

      return result
    }
  },

  methods: {
    _openPlayerDetails() {
      playerPaneStore().setSelectedPlayerUID(this.item.playerUID)
    },
  },
}
</script>

<template>
  <div>
    <div :class="_cssClasses">
      <div class="line1 flex text-left" @click="_openPlayerDetails">
        <player-picture :uid="item.playerUID" size="68px"/>
        <div class="flex-grow ml-1">
          <div class="flex pr-1">
            <player-name :uid="item.playerUID"/>
            <div class="flex-grow"/>
            <player-role :role="pr" v-for="pr in _playerRoles" style="margin-left: -4px" class="mt-1"/>
          </div>
          <div class="flex mx-1">
            <team-logo :uid="_homeHomeTeamUID" size="small"/>
            <team-name :uid="_homeHomeTeamUID" :highlight="_homeHomeTeamUID === item.teamUID" style="font-size: 9pt"/>
            <span class="white mx-1" style="font-size: 9pt; line-height: 24px">-</span>
            <team-name :uid="_homeAwayTeamUID" :highlight="_homeAwayTeamUID === item.teamUID" style="font-size: 9pt"/>
            <team-logo :uid="_homeAwayTeamUID" size="small"/>
          </div>
          <div class="mr-1">
            <selection-pane-item-line-game-data :game-uid="item.gameUID" style="border: none"/>
          </div>

        </div>
      </div>
      <hr class="m-0 p-0"/>

      <div class="player-stats-block text-left" v-if="_playerStats">
        <div class="flex">
          <fieldset class="m-1 text-left flex-grow">
            <legend>{{ _playerStats.goalsTotal }} Buts</legend>
            <p class="px-1 pb-1 mb-0">
              Soit {{ (_playerStats.goalsTotal / _playerStats.gamesPlayed).toFixed(1) }}/m
              <span v-if="_playerStats.goalsTotal > 0">({{ (_playerStats.minutesPlayed / _playerStats.goalsTotal).toFixed(0) }}min)</span>
            </p>
          </fieldset>
          <fieldset class="m-1 text-left flex-grow">
            <legend>{{ _playerStats.goalsAssists }} Passes</legend>
            <p class="px-1 pb-1 mb-0">
              Soit {{ (_playerStats.goalsAssists / _playerStats.gamesPlayed).toFixed(1) }}/m
              <span v-if="_playerStats.goalsAssists > 0">({{ (_playerStats.minutesPlayed / _playerStats.goalsAssists).toFixed(0) }}min)</span>
            </p>
          </fieldset>
          <fieldset class="m-1 text-left inline-block">
            <legend>{{ _playerStats.yellowCards + _playerStats.redCards }} Cartons</legend>
            <p class="px-1 pb-1 mb-0">
              {{ _playerStats.yellowCards }}
              <font-awesome-icon icon="fa-square" style="color: yellow" class="mx-1"/>
              {{ _playerStats.redCards }}
              <font-awesome-icon icon="fa-square" style="color: red" class="ml-1"/>
            </p>
          </fieldset>
        </div>
        <div class="flex">
          <fieldset class="m-1 text-left inline-block">
            <legend>{{ _playerStats.gamesPlayed }} matchs joués</legend>
            <p class="px-1 pb-1 mb-0">
              Dont {{ _playerStats.lineups }} titularisations. Le joueur a été remplacé {{ _playerStats.subsOut }} fois.
              Pour un total de {{ _playerStats.minutesPlayed }} minutes jouées.
            </p>
          </fieldset>
          <fieldset class="my-1 mr-1 text-right inline-block" style="width: 190px">
            <legend>Note corresp.</legend>
            <div class="pr-1 item-matching-indicator" v-if="_matchingScore > 0">
              <a-tooltip placement="top" :title="_matchingScoreTooltip">
                <font-awesome-icon icon="fa-regular fa-star" v-if="_relativeMatchingScore < 1/6.0"
                                   :style="{color: _relativeMatchingScore > ((0/6.0 + 1/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                                   class="mr-1"/>
                <font-awesome-icon icon="fa-solid fa-star" v-else
                                   :style="{color: _relativeMatchingScore >= ((1/6.0 + 2/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                                   class="mr-1"/>

                <font-awesome-icon icon="fa-regular fa-star" v-if="_relativeMatchingScore <= 3/6.0"
                                   :style="{color: _relativeMatchingScore >= ((2/6.0 + 3/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                                   class="mr-1"/>
                <font-awesome-icon icon="fa-solid fa-star" v-else
                                   :style="{color: _relativeMatchingScore >= ((3/6.0 + 4/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                                   class="mr-1"/>

                <font-awesome-icon icon="fa-regular fa-star" v-if="_relativeMatchingScore <= 5/6.0"
                                   :style="{color: _relativeMatchingScore >= ((4/6.0 + 5/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                                   class="mr-1"/>
                <font-awesome-icon icon="fa-solid fa-star" v-else
                                   :style="{color: _relativeMatchingScore >= ((5/6.0 + 6/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                                   class="mr-1"/>
              </a-tooltip>
              <button class="small square p-0 mr-1">
                {{ _matchingScore }}
              </button>
            </div>
          </fieldset>
        </div>
      </div>

      <div>
        <span v-for="(statUIDs,period) in item.statsMatch">
          <statistic :uid="statUID" :period="period" v-for="statUID in statUIDs" :positive="true"
                     :show-player-picture="false"/>
        </span>
        <span v-for="(statUIDs,period) in item.statsNotMatch">
          <statistic :uid="statUID" :period="period" v-for="statUID in statUIDs" :positive="false"
                     :show-player-picture="false"/>
        </span>
      </div>

      <!-- Onglets -->
      <player-pane-item-tabs :player-uid="item.playerUID" :game-uid="item.gameUID" v-if="_isSelected"/>
      <hr/>
    </div>

    <div v-if="showAd" class="inline-block text-center my-5">
      <gambling-affiliation-ad/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.player-pane-item {
  background: rgb(255, 255, 255);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  border-radius: @radius-regular;
  cursor: pointer;
  border: 1px solid #444;

  &:hover {
    background: @title-color;
    background: linear-gradient(135deg, fade(@title-color, 20%) 0%, fade(@title-color, 15%) 10%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  }

  &.selected {
    .line1 {
      background: -webkit-linear-gradient(-135deg, @home-color, @away-color, transparent, transparent);
    }
  }

  .line0 {
    height: 20px;
  }


  .line2 {
    display: flex;
    height: 44px !important;
    text-align: center;

    .item-matching-indicator {
      font-size: @font-size-small;
      line-height: 44px;
    }

  }
}
</style>