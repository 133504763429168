<script>
import {contextStore} from "@/stores/Context.store";
import MainHeader from "@/components/common/MainHeader.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import StatSelectorModal from "@/components/common/modal/StatSelector.Modal.vue";
import TeamRouteHeader from "@/components/routes/stats/StatsRouteHeader.vue";
import WidgetParameterSelectorModal from "@/components/common/modal/WidgetParameterSelector.Modal.vue";
import {perspectiveStore} from "@/stores/Perspective.store";
import UserAccountPreferences from "@/components/common/modal/user_preferences/category/UserAccountPreferences.vue";
import CalculatorModal from "@/components/common/modal/calculator/CalculatorModal.vue";
import UserPreferencesModal from "@/components/common/modal/user_preferences/UserPreferencesModal.vue";
import WidgetSettingsModal from "@/components/common/modal/widget_settings/WidgetSettingsModal.vue";
import TeamRouteBottomBar from "@/components/routes/stats/StatsRouteBottomBar.vue";
import PerspectiveSettingsModal from "@/components/common/modal/perspective_settings/PerspectiveSettingsModal.vue";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import SearchModal from "@/components/common/modal/search/SearchModal.vue";
import GameModal from "@/components/common/modal/game/GameModal.vue";
import TeamModal from "@/components/common/modal/team/TeamModal.vue";
import Statistic from "@/components/common/Statistic.vue";
import PaypalModal from "@/components/common/modal/paypal/PaypalModal.vue";
import EventStatsModal from "@/components/routes/stats/modals/FrequencyStatsModal.vue";
import FrequencyStatsModal from "@/components/routes/stats/modals/FrequencyStatsModal.vue";
import GameDetailsModal from "@/components/common/modal/game/GameDetailsModal.vue";
import LeagueGroupsModal from "@/components/common/modal/selection_league_modal/LeagueGroupsModal.vue";
import LeagueGroupModal from "@/components/common/modal/selection_league_modal/LeagueGroupModal.vue";

// Widgets
import FrequencyStatsWidget from "@/components/routes/stats/widgets/FrequencyStats.Widget.vue";
import RawStatsWidget from "@/components/routes/stats/widgets/RawStats.Widget.vue";
import SerieStatsWidget from "@/components/routes/stats/widgets/SerieStats.Widget.vue";
import RawStatsModal from "@/components/routes/stats/modals/RawStatsModal.vue";
import SerieStatsModal from "@/components/routes/stats/modals/SerieStatsModal.vue";
import YoutubeButton from "@/components/common/YoutubeButton.vue";
import {userStore} from "@/stores/User.store";

export default {
  name: "TeamRoute",
  components: {
    YoutubeButton,
    SerieStatsModal,
    RawStatsModal,
    FrequencyStatsModal,
    LeagueGroupModal,
    LeagueGroupsModal,
    GameDetailsModal,
    EventStatsModal,
    PaypalModal,
    Statistic,
    TeamModal,
    GameModal,
    SearchModal,
    PerspectiveSettingsModal,
    TeamRouteBottomBar,
    WidgetSettingsModal,
    UserPreferencesModal,
    CalculatorModal,
    UserAccountPreferences,
    WidgetParameterSelectorModal,
    TeamRouteHeader, StatSelectorModal, TeamLogo, MainHeader,
    // widgets
    FrequencyStatsWidget,
    RawStatsWidget,
    SerieStatsWidget
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _selectedPerspectiveUID() {
      return statsRouteStore().getSelectedPerspectiveUID()
    },
    _hasWidgets() {
      if (!this._items) return false
      return this._items.length > 0
    },
    _items() {
      if (!this._selectedPerspectiveUID) return []
      return perspectiveStore().getWidgetsByPerspectiveUID(this._selectedPerspectiveUID)
    },
    _notAuthenticated() {
      return !contextStore().isAuthenticated()
    },
  },
  methods: {
    _itemType(item) {
      if (!item) return null

      // Fix temporaire pour changement de nom
      if (item.type === 'TeamEventStatsWidget') {
        return 'FrequencyStatsWidget'
      }

      return item.type
    },
    _openStatSelectorModal() {
      window.emitter.emit('stats-route:open-stat-selector-modal')
    },
    _signWithGoogle() {
      userStore().signInWithGoogle()
    }
  },
  beforeMount() {

    if (contextStore().shouldReload()) {
      window.location.href = window.location.origin
    }

    if (!contextStore().isInitialized()) {
      this.$router.push({name: 'init', query: {redirect: 'stats', query: this._urlUID}})
      return
    }

    statsRouteStore().init()
  }
}
</script>

<template>
  <div :class="{'mobile': _isMobile}">
    <main-header/>
    <div id="stat-pane">
      <team-route-header/>
      <div id="team-content-wrapper">
        <component :is="_itemType(item)" :item="item" v-for="item in _items" v-if="_hasWidgets"/>
        <div id="empty-perspective" v-else-if="!_hasWidgets">
          <p>Cette perspective ne contient aucune stats</p>
          <button class="accent" @click="_openStatSelectorModal">Ajouter une statistique</button>
          <youtube-button video-id="MDMyT0NFZ8A" class="ml-2"/>
        </div>
      </div>
      <team-route-bottom-bar/>
    </div>
    <div id="just-sign-in" v-if="_notAuthenticated">
      <h2 class="p-2 pt-4 uppercase">Tout est gratuit mais...</h2>
      <p class="px-3">Tu peux accéder aux stats de toutes les équipes, dans toutes les ligues mais il y a une condition...</p>
      <p class="px-3">Tu dois avoir un compte DoctoBET ! Sans cela, seules quelques informations te sont proposées et tu n'as pas la possibilité de personnalisé tes stats.</p>
      <button class="primary mb-4" @click="_signWithGoogle()">Créer mon compte</button>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#team-content-wrapper {
  height: calc(100vh - @toolbar-regular * 2 - @toolbar-small);
  gap: @padding-regular; /* Adjust the gap as needed */
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;

  margin: @padding-small;
  border-radius: @radius-regular;

  .widget {
    flex: 1 1 350px; /* permet aux div de croître et se contracter, avec une base de 350px */
    box-sizing: border-box;
    width: 350px;
    max-width: calc(100%);
  }
}

#stat-pane {
  margin: @padding-small;
  border-radius: @radius-regular;
  background: rgb(94,53,43);
  background: linear-gradient(122deg, rgba(94,53,43,1) 0%, rgba(94,53,43,0.95) 3%, rgba(37,37,42,0.85) 21%, rgba(37,37,42,0.75) 100%);
  height: calc(100dvh - @toolbar-regular);
}

#empty-perspective {
  margin-left: auto;
  margin-right: auto;
  p {
    margin: 200px auto @padding-large auto;
    max-width: 400px;
    text-align: center;
    font-size: 2em !important;
    color: @text-color;
    opacity: 0.1;
  }
}

@media (min-width: 599px) {F
  #empty-perspective {
    width: calc(100vw - 400px);
  }
}

#just-sign-in{
  position: fixed;
  bottom: 12px;
  right: 12px;
  width: 300px;
  border: 1px solid @stroke-color;
  border-radius: @radius-regular;
  background: #494081;

  p{
    text-align: center;
  }
}
</style>