<template>
  <div id="player-pane">
    <player-pane-header/>

    <div class="player-results">
      <div class="player-results-content">

        <a-alert v-if="!_isRunningSelection && _gameAnalysedCountMessage" :message="_gameAnalysedCountMessage"
                 class="mx-1" type="info"
                 style="padding: 2px 8px"/>
        <a-alert v-if="_resultsCount === 0 && _filterActive" :message="_filterActiveMessage" class="mx-1 my-1"
                 type="info"
                 style="padding: 2px 8px"/>
        <a-alert v-if="_arjelOnly === false" type="warning" class="mx-1 my-1" message="Filtre ARJEL en place"
                 show-icon/>

        <div class="not-allowed-results p-1" v-if="_notAllowedLowLevel">
          <a-alert :message="_notAllowedResultsMessage"
                   type="warning" show-icon/>
          <fieldset class="not-allowed-leagues mt-1" v-if="_notAllowedLeaguesPRO && _notAllowedLeaguesPRO.length > 0">
            <legend class="m-0">{{ _notAllowedResultsCountPRO }} dans l'offre PRO</legend>
            <div class="not-allowed-league" v-for="l in _notAllowedLeaguesPRO">
              <league-logo :uid="l" size="small" style="margin-top: 1px"/>
              <league-name :uid="l" class="ml-1 inline-block" size="small" :show-area="true"/>
            </div>
          </fieldset>
          <fieldset class="not-allowed-leagues mt-1"
                    v-if="_notAllowedLeaguesULTIMATE && _notAllowedLeaguesULTIMATE.length > 0">
            <legend class="m-0">{{ _notAllowedResultsCountULTIMATE }} dans l'offre ULTIMATE</legend>
            <div class="not-allowed-league" v-for="l in _notAllowedLeaguesULTIMATE">
              <league-logo :uid="l" size="small" style="margin-top: 1px"/>
              <league-name :uid="l" class="ml-1 inline-block" size="small" :show-area="true"/>
            </div>
          </fieldset>
          <div class="flex mt-1">
            <button class=" accent flex-grow " @click="_buy('BASIC')"
                    v-if="_notAllowedLeaguesPRO && _notAllowedLeaguesPRO.length > 0">
              Passer PRO
            </button>
            <div v-if="_notAllowedLeaguesPRO && _notAllowedLeaguesULTIMATE">&nbsp;</div>
            <button class=" primary flex-grow " @click="_buy('FULL')"
                    v-if="_notAllowedLeaguesULTIMATE && _notAllowedLeaguesULTIMATE.length > 0">
              Passer ULTIMATE
            </button>
          </div>
        </div>

        <font-awesome-icon icon="fa-solid fa-spinner" :spin="true" size="2xl" style="color: #6DFFC2"
                           v-if="_isRunningSelection" class="m-3"/>
        <div v-else class="selection-pane-groups" v-for="(key, index) in _orderedGroupementKeys">
          <player-pane-item v-for="(item, itemIndex) in _groupedResults[key].items"
                            :key="item.playerUID"
                            v-if="_groupedResults[key]"
                            :item="item" :show-ad="index===2 && itemIndex===0" class="mb-3 mx-1"/>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import PlayerPaneHeader from "@/components/routes/player/panes/player/PlayerPaneHeader.vue";
import {playerPaneStore} from "@/components/routes/player/panes/player/PlayerPane.store";
import SelectionPaneItem from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.vue";
import {preferencesStore} from "@/stores/Preferences.store";
import dateService from "@/services/date.service";
import {leagueStore} from "@/stores/League.store";
import PlayerPaneItem from "@/components/routes/player/panes/player/PlayerPaneItem.vue";
import {contextStore} from "@/stores/Context.store";
import LeagueName from "@/components/common/LeagueName.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";

export default {
  name: "PlayerPane",
  components: {LeagueLogo, LeagueName, SelectionPaneItem, PlayerPaneHeader, PlayerPaneItem},

  data() {
    return {
      displayedPage: 1
    }
  },

  mounted() {
    playerPaneStore().runSelection();
  },

  methods: {},
  computed: {
    _orderedLeaguesUIDs() {
      // On récupère les ligues favorites
      const favoriteLeagues = preferencesStore().getValues('LEAGUE', 'FAVORITE_LEAGUES_' + contextStore().getSport);
      if (!favoriteLeagues || !favoriteLeagues.length) return Object.keys(this._groupedResults)
      const favoriteLeaguesUIDs = favoriteLeagues.map(l => Number(l))

      const favUIDs = []
      const otherUIDs = []
      Object.keys(this._groupedResults).forEach(leagueUID => {
        if (favoriteLeaguesUIDs.includes(Number(leagueUID))) {
          favUIDs.push(leagueUID)
        } else {
          otherUIDs.push(leagueUID)
        }
      })

      // On place les ligues favorites en premier
      return favUIDs.concat(otherUIDs)
    },
    _orderedGroupementKeys() {
      const critereRegroupement = preferencesStore().getFirstValue('SELECTION_PLAYER', 'CRITERE_REGROUPEMENT');
      if (!critereRegroupement || critereRegroupement === 'NONE') return ['NONE']

      // si le critère n'est pas défini, on utilise le paramètre par défaut
      return Object.keys(this._groupedResults)
    },
    _isRunningSelection() {
      return playerPaneStore().isRunningSelection
    },
    _gameAnalysedCountMessage() {
      let total = this._gameAnalysedCount
      if (!total) return "Aucun match trouvé"

      let results = selectionPaneStore().results.length
      if (!results === null) return `0 résultat / ${total} matchs`
      if (results === 1) return `1 résultat / ${total} matchs`
      return `${results} résultats / ${total} matchs`
    },
    _results() {
      return playerPaneStore().results
    },
    _groupedResults() {

      // group by leagueUID
      const critereRegroupement = preferencesStore().getFirstValue('SELECTION_PLAYER', 'CRITERE_REGROUPEMENT');

      let results = [...this._results];

      const completeGroupedBy = results.reduce((acc, item) => {

        let regroupementKey
        switch (critereRegroupement) {
          case 'NONE':
          default:
            regroupementKey = 'NONE'
            break
        }

        if (!acc[regroupementKey]) {
          acc[regroupementKey] = []
        }
        acc[regroupementKey].push(item)
        return acc
      }, {})

      Object.keys(completeGroupedBy).forEach(key => {

        let area = null
        let label = null
        let leagueUID = null
        switch (critereRegroupement) {
          case 'DATETIME':
            label = key
            break
          case 'NONE':
            label = 'Tous'
            break
          case 'LEAGUE':
          default:
            let league = leagueStore().get(key)
            if (!league) break

            label = league.name + (league.currentSeason ? ' - ' + league.currentSeason : '')
            area = league.area
            leagueUID = league.uid
            break
        }

        completeGroupedBy[key] = {
          area: area,
          leagueUID: leagueUID,
          label: label,
          items: completeGroupedBy[key]
        }
      })

      // tri des items
      const critereTri = preferencesStore().getFirstValue('SELECTION', 'CRITERE_TRI')

      Object.keys(completeGroupedBy).forEach(key => {
        if (!critereTri || critereTri === 'NOTE') {
          completeGroupedBy[key].items.sort(function (a, b) {
            return b.matchingScore - a.matchingScore;
          })
        }

        if (critereTri === 'DATETIME') {
          completeGroupedBy[key].items.sort(function (a, b) {
            return dateService.compare(a.gameDatetime, b.gameDatetime);
          })
        }
      })

      // On ne va garder que this.displayedPage * 20 resultats
      let canBeAddedCount = this.displayedPage * 20
      const displayedGroupedBy = {}
      Object.keys(completeGroupedBy).forEach(key => {
        if (canBeAddedCount <= 0) return
        const itemsToDisplay = completeGroupedBy[key].items.slice(0, canBeAddedCount)
        displayedGroupedBy[key] = {
          area: completeGroupedBy[key].area,
          leagueUID: completeGroupedBy[key].leagueUID,
          label: completeGroupedBy[key].label,
          items: itemsToDisplay
        }
        canBeAddedCount -= itemsToDisplay.length
      })

      return displayedGroupedBy
    },
    _arjelOnly() {
      return playerPaneStore().arjelOnly
    },
    _gameAnalysedCount() {
      return playerPaneStore().gameAnalyzedCount
    },
    _resultsCount() {
      return playerPaneStore().results.length
    },
    _filter() {
      return playerPaneStore().filter
    },
    _filterActive() {
      if(!this._filter) return null
      if (this._filter.length > 2) return this._filter
      return null
    },
    _filterActiveMessage() {
      if (!this._filterActive) return ""
      return 'Aucun résultat avec la recherche "' + this._filterActive + '"'
    },
    _notAllowedLowLevel() {
      const all = playerPaneStore().notAllowedResultsCount
      if (!all) return null
      if (all['BASIC']) {
        return 'BASIC'
      }
      if (all['FULL']) {
        return 'FULL'
      }
      return null
    },
    _notAllowedResultsCountPRO() {
      if (!this._notAllowedLowLevel) return 0
      const all = playerPaneStore().notAllowedResultsCount
      return all['BASIC'] ? all['BASIC'] : 0
    },
    _notAllowedResultsCountULTIMATE() {
      if (!this._notAllowedLowLevel) return 0
      const all = playerPaneStore().notAllowedResultsCount
      return all['FULL'] ? all['FULL'] : 0
    },
    _notAllowedResultsMessage() {
      if (!this._notAllowedLowLevel) return null
      const total = this._notAllowedResultsCountPRO + this._notAllowedResultsCountULTIMATE
      return total + ' résultats réservés aux abonnés.'
    },
    _notAllowedLeaguesPRO() {
      if (!this._notAllowedLowLevel) return null
      const all = playerPaneStore().notAllowedLeagues
      return all['BASIC']
    },
    _notAllowedLeaguesULTIMATE() {
      if (!this._notAllowedLowLevel) return null
      const all = playerPaneStore().notAllowedLeagues
      return all['FULL']
    }
  },
  watch: {}
}
</script>
<style lang="less">
@import "@/assets/styles/variables.less";

#player-pane {
  margin: @padding-small 0 @padding-small @padding-small;
  border-radius: @radius-regular;
  background: rgb(94, 53, 43);
  background: linear-gradient(122deg, rgba(94, 53, 43, 1) 0%, rgba(94, 53, 43, 0.95) 3%, rgba(37, 37, 42, 0.85) 21%, rgba(37, 37, 42, 0.75) 100%);
  height: calc(100dvh - @toolbar-regular);
  width: 400px !important;
  min-width: 400px !important;

  .player-results {
    height: calc(100dvh - (@toolbar-regular * 3));
    overflow-y: scroll;
    scrollbar-width: none;
  }
}

.mobile {
  #player-pane {
    width: calc(100vw - @padding-small) !important;
    min-width: calc(100vw - @padding-small) !important;
  }
}

</style>